import './App.css';

import { Link } from 'react-router-dom';

const Packages = (props) => {
    const descriptions = props.data.points.map(data => <li>• {data}</li>);

    return (
        <div className="package-outer">
            <div className="icon">
                <img src={require(`../img/${props.data.icon}`).default} alt='' />
            </div>
            <div className="packages" style={{ backgroundColor: props.data.color }}>
                <div className="top-portion">
                    <h1 className="package-title ">{props.data.title}</h1>
                    <h2 className="rate"><b className="dollar-sign">$</b>{props.data.rate}</h2>
                    <a className="register" href="#footer" style={{ color: props.data.color }}>register</a>
                </div>
                <div className="bottom-portion" >
                    <p className="description-title">{props.data.subtitle}</p>
                    <ul>{descriptions}</ul>
                    <Link to={props.data.link}> <a className="see-more" style={{ color: props.data.color }}>see more</a> </Link>
                </div>
            </div>
        </div>
    )
}

export default Packages;