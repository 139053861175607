import './App.css';

import { Link } from 'react-router-dom';

const AboutUs = (props) => {

    const aboutUsGrid = props.data.paragraphPoints.map(data => <p>{data}</p>);

    return (
        <div className="AboutUs" style={{ backgroundColor: props.data.color }} id="about">
            <div className="container">
                <h1>{props.data.title}</h1>
                <div className="points">
                    {aboutUsGrid}
                    <p className="motto">&#34;{props.data.motto}&#34;</p>
                </div>
                <Link to={props.data.link}>{props.data.buttonText}</Link>
            </div>
        </div >
    )
}

export default AboutUs;