import './App.css';

const Press = (props) => {

    const pressItems = props.data.map(data => <li><img src={require(`../img/press/${data.icon}`).default} alt='' /> <br /> <a href={data.link} target="_blank">{data.desc}</a></li>);

    return (
        <div className="press">
            <div className="container">
                <h1 className="title press-title">Appearances</h1>
                <ul>{pressItems}</ul>
            </div>
        </div >
    )
}

export default Press;