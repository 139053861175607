import './App.css';

const Partners = (props) => {

    let id = 0;
    const partnersGrids = props.data.map((data, id) =>
        <div className={`grid-item-${id + 1} grid-item`}>
            <a href={data.link} target="_blank">
                <img src={require(`../img/partners/${data.image}`).default} alt='' />
                <h2 >{data.name}</h2>
            </a>
        </div>
    );
    return (
        <div className="partners">
            <div className="container">
                <h1 className="title partners-title">Partners</h1>
                <div className="partners-grid">
                    {partnersGrids}
                </div>
            </div>
        </div>
    )
}

export default Partners;