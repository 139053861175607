import './App.css';

const Hero = (props) => {
    return (
        <div className="hero">
            <div className="container">
                <h2 className="sub-title">set pieces skills coaching</h2>
                <h1 className="title"><b className="underline">ray</b> barkwill</h1>
                <p className="statement">Hooker and Frontrow Coaching, Skill Development, Throwers-Scrum-Lineout,
                    Remote Coaching - Scrum & Lineout Camps.</p>
                <div className="button-flex">
                    <p className="button button-fill"><a href="#training">trainings</a></p>
                    <p className="button button-outline"><a href="/#footer">contact me</a></p>
                </div>
            </div>
        </div>
    )
}

export default Hero;