import './App.css';
import React, { useState } from 'react';

import Data from "./TextData.json";

/* Components */
import NavBar from './NavBar';
import Hero from './Hero';
import AboutUs from './AboutUs';
import Packages from './Packages'
import SimplePage from './SimplePage';
import Press from './Press';
import Patners from './Partners';
import OurTeam from './OurTeam';
import Footer from './Footer';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

const TrainingPage = () => {
  return (
    <div className="container" id="training">
      <h1 className="title trainings-title">Trainings</h1>
      <div className="trainings">
        <Packages data={Data[0].package["player-assesment"]} img="../img/Assesment.svg" />
        <Packages data={Data[0].package["remote-throwing-plan"]} img="../img/Assesment.svg" />
        <Packages data={Data[0].package["group-session"]} img="../img/Assesment.svg" />
      </div>
    </div>
  )
}

const Frontpage = () => {
  return (
    <div className='front-page'>
      <Hero />
    </div>
  )
}



function App() {
  return (
    <Router>
      <img src={require('../img/Canterbury2.svg').default} className='watermark' alt='' />
      <NavBar />
      <switch>
        <Route exact path="/">
          <Frontpage />
          <AboutUs data={Data[0].simplePageData["AboutUs"]} />
          <TrainingPage />
          <Press data={Data[0].arrayitem["press-list"]} />
          <Patners data={Data[0].arrayitem["partners-list"]} />
          <Footer data={Data[0].arrayitem["partners-list"]} />
        </Route>

        <Route path="/player-assesment">
          <SimplePage path="/" data={Data[0].simplePageData["player-assesment"]} />
        </Route>
        <Route path="/remote-throwing-plan">
          <SimplePage path="/" data={Data[0].simplePageData["remote-throwing-plan"]} />
        </Route>
        <Route path="/group-session">
          <SimplePage path="/" data={Data[0].simplePageData["group-session"]} />
        </Route>
        <Route path="/our-team">
          <OurTeam />
        </Route>
      </switch>
    </ Router>
  );
}

export default App;
