import './App.css'

const SimplePage = (props) => {
    return (
        <div className="AboutUs SimplePage" style={{ backgroundColor: props.data.color }}>
            <div className="container">
                <h1>{props.data.title}</h1>
                <p>{props.data.paragraph}</p>
                <a href={props.data.link}>{props.data.buttonText}</a>
            </div>
        </div >
    )
}

export default SimplePage;