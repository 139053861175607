import './App.css';
import React, { useState } from 'react';

import { Link } from 'react-router-dom';

const NavBar = (props) => {

    const [open, setOpen] = useState(false);

    return (
        <header>
            <div className={open ? "container active" : 'container inactive'}>
                <Link to="/"><img src={require('../img/Logo.svg').default} className='logo' alt='' /></Link>
                <div className="menu-controlls">
                    <img src={require('../img/HamburgerNav.svg').default} className={!open ? "menu active" : 'menu inactive'} alt='' onClick={() => setOpen(true)} />
                    <img src={require('../img/XsymbolforNav.svg').default} className={open ? "menu active" : 'menu inactive'} alt='' onClick={() => setOpen(false)} />
                </div>
                <ul className={open ? "nav-list active" : 'nav-list inactive'}>
                    <li><Link to="/our-team" onClick={() => setOpen(false)}>our team</Link></li>
                    <li><a href="/#about" onClick={() => setOpen(false)} >what we do</a></li>
                    <li><a href="/#training" onClick={() => setOpen(false)} >trainings</a></li>
                    <li><a href="/#footer" onClick={() => setOpen(false)}>Contact Us</a></li>
                </ul>
            </div>
        </header >
    )
}

export default NavBar;