import './App.css';

const OurTeam = () => {
    return (
        <div className="OurTeam" style={{ backgroundColor: "#2596C8" }}>
            <div className="container">
                <h1 className="title our-team-title">Our Team</h1>
                <ul className="team-list">
                    <li>
                        <h2 className="team-title">Ray Barkwill</h2>
                        <h3 className="team-title-desc">Founder, and Ambassador of Canterbury</h3>

                        <ul className="professional-qualifications qualifications">
                            <h3 className="desc-title">Professional Qualifications</h3>
                            <li>
                                <p>o	Masters of Education-Coaching Studies - University of Victoria 21’</p>
                                <p className="indent">o	Thesis: Isolation Managing Performance Anxiety for High Performance Rugby Players</p>
                            </li>
                            <li><p>o	Graduate Diploma Education - Curtin University 11’ </p></li>
                            <li><p>o	BSc Physical Geography - Brock University 10’ </p></li>
                            <li><p>o	Level 2 World Rugby Coaching Certification (Level 3 in progress Covid delayed)  </p></li>
                            <li><p>o	Teacher for 10 years; Registered with Ontario College of Teachers & British Columbia College of Teachers  </p></li>
                        </ul>
                        <ul className="rugby-qualifications qualifications">
                            <h3 className="desc-title">Rugby Qualifications</h3>
                            <li>
                                <p>o	Professional and international player for 10 years </p>
                                <p className="indent">o	56 Caps for Canada, University of Western Australia, Western Force, Perth Spirit, Seattle Seawolves </p>
                            </li>
                            <li><p>o	Canada Throwing/Breakdown Coach RWC  2019</p></li>
                            <li><p>o	Canada Forward Coach 2019 ARC & PNC </p></li>
                            <li><p>o	Forward Coach - U18 Canada -2018-current</p></li>
                            <li><p>o	Teacher for 10 years; Registered with Ontario College of Teachers & British Columbia College of Teachers  </p></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div >
    )
}

export default OurTeam;